// STYLES COMMON TO ALL THEMES

@use 'sass:color';

// import the icons
@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0';

@import 'bootstrap/scss/bootstrap';

// Common component styles (applying to all themes)
@import './components/contrast';
@import './components/navbar';
@import './components/footer';
