/*
For any navbar where bg=dark,
set all the colors to their dark variants automatically
rather than defaulting to variant=light
and forcing the developer to specify variant=dark.
 */
 .navbar.bg-dark {
    --#{$prefix}navbar-color: #{$navbar-dark-color};
    --#{$prefix}navbar-hover-color: #{$navbar-dark-hover-color};
    --#{$prefix}navbar-disabled-color: #{$navbar-dark-disabled-color};
    --#{$prefix}navbar-active-color: #{$navbar-dark-active-color};
    --#{$prefix}navbar-brand-color: #{$navbar-dark-brand-color};
    --#{$prefix}navbar-brand-hover-color: #{$navbar-dark-brand-hover-color};
    --#{$prefix}navbar-toggler-border-color: #{$navbar-dark-toggler-border-color};
  }
  
  /*
  For any navbar where bg=primary,
  set all the colors to their light or dark variants to maximize contrast ratio
  automatically, rather than defaulting to variant=light
  and forcing the developer to specify which variant to use.
   */
  .navbar.bg-primary {
    --#{$prefix}navbar-color: #{color-contrast($primary, $navbar-dark-color, $navbar-light-color)};
    --#{$prefix}navbar-hover-color: #{color-contrast($primary, $navbar-dark-hover-color, $navbar-light-hover-color)};
    --#{$prefix}navbar-disabled-color: #{color-contrast($primary, $navbar-dark-disabled-color, $navbar-light-disabled-color)};
    --#{$prefix}navbar-active-color: #{color-contrast($primary, $navbar-dark-active-color, $navbar-light-active-color)};
    --#{$prefix}navbar-brand-color: #{color-contrast($primary, $navbar-dark-brand-color, $navbar-light-brand-color)};
    --#{$prefix}navbar-brand-hover-color: #{color-contrast($primary, $navbar-dark-brand-hover-color, $navbar-light-brand-hover-color)};
    --#{$prefix}navbar-toggler-border-color: #{color-contrast($primary, $navbar-dark-toggler-border-color, $navbar-light-toggler-border-color)};
  }
  