// font-family: 'Jost', sans-serif;
//@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$shell-grey-darker: #404040;
$shell-grey-dark: #595959;
$shell-grey-mid: #a6a6a6;
$shell-grey-light: #d9d9d9;
$shell-grey-lighter: #f7f7f7;
$shell-yellow: #FBCE07;

$blue: #063e86;
$green: #108346;
$yellow: $shell-yellow;
$red: #DA291C;

$light: $shell-grey-lighter;
$dark: $shell-grey-darker;
$primary: $red;
$secondary: $yellow;
$danger: $red;
$warning: $yellow;
$success: $green;
$info: $dark;

$body-color: $shell-grey-darker;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-xl: 0;
$border-radius-2xl: 0;



@font-face {
  font-family: 'Shell';
  font-weight: 500;
  font-style: normal;
  src: url('fonts/Shell-Medium.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Shell-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Shell-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/Shell-Medium.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('fonts/Shell-Medium.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/Shell-Medium.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-family: 'Shell';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/Shell-Light.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Shell-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('fonts/Shell-Light.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('fonts/Shell-Light.woff') format('woff'),
    /* Pretty Modern Browsers */
    url('fonts/Shell-Light.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('fonts/Shell-Light.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

$font-family-base: 'Shell';
$font-size-base: 1rem;

@import './common';

// OVERRIDES of the common styles

hr {
  color: $shell-grey-darker;
}

.content-block {

  &.content-block--image {
    .col {
      background-color: $shell-grey-light;
    }
  }

  .subtitle {
    color: $shell-grey-darker;
    font-size: 1rem;
  }

  h2.title {
    font-size: 1.2rem;
  }
  
}

.learn {
  & > .content-block:first-child {
    background-color: $secondary;
  }
}

.create {
  & > .content-block:first-child {
    background-color: $secondary;
  }
}

.brands-list {
  a {
    color: $shell-grey-darker;

    .card {
      background-color: $shell-grey-light;
    }
  }
  a:hover, a:focus {
    .card {
      background-color: $shell-grey-mid;
    }
  }
  .card {
    border: none;
  }
}

.form-select {
  background-color: $shell-grey-light;
  border: none;
  border-bottom: 1px solid black;
}

.dropdown-selector {
  .form-label {
    color: $shell-grey-darker;
  }
}