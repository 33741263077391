// Make the footer appear at the bottom even when the content does not fill the height of the document.
#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .footer {
        margin-top: auto;
    }    
}
